import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify';
import VueI18n from 'vue-i18n';
import messages from './lang';
import AsyncComputed from 'vue-async-computed'
import moment from 'moment'

var SHA256 = require("crypto-js/sha256");

Vue.config.productionTip = process.env.NODE_ENV === 'development'
console.log('system is ' + process.env.NODE_ENV)

Vue.use(VueI18n);
Vue.use(AsyncComputed)

export const i18n = new VueI18n({
    locale: 'de',
    fallbackLocale: 'de',
    messages
});

Vue.prototype.moment = moment

Vue.filter('prettyDate', function (value: any) {
        if (value) {
            return moment(String(value)).format('DD.MM.YYYY').toString()
        } else return ''
    }
)

Vue.filter('encrypt', function (password: string) {
        if (password) {
            return SHA256(password).toString()
        } else return ''
    }
)

new Vue({
    router,
    store,
    vuetify,
    i18n,
    // db, fireApp,
    render: h => h(App)
}).$mount('#app')





