import '@mdi/font/css/materialdesignicons.css' // Ensure you are using css-loader
import Vue from 'vue';
import Vuetify from 'vuetify/lib';

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        themes: {
            light: {
                primary: '#FFCC33',
                secondary: '#3B3B3B', // #FFCDD2
                accent: '#F8F8F8', // #3F51B5

                success: '#bffabf',
                warning: '#f6fab0',
                error: '#fa989c'
            },
        },
    },
    icons: {
        iconfont: 'mdi', // default - only for display purposes
    },
});
