import Vue from 'vue'
import Vuex from 'vuex'
import {CustomerBuilder} from "@/data/builder/CustomerBuilder"
import {db, DB_COLLECTION, fireApp, fstore} from "@/database"
import {ApplicationUser, Role} from "@/data/model/ApplicationUser"
import {i18n} from "@/main";
import {ApplicationUserBuilder} from "@/data/builder/ApplicationUserBuilder";
import {Costumer} from "@/data/model/Costumer";
import {Project} from "@/data/model/Project";
import moment from "moment";
import firebase from "firebase";
import functions = firebase.functions;
import {OrderTypes} from "@/data/model/OrderElement";
var SHA256 = require("crypto-js/sha256");

Vue.use(Vuex)

function exists(promiseData: any): boolean {
    return promiseData.docs[0] != undefined && promiseData.docs[0].exists
}

enum SnachbarType {
    INFO = 'info',
    ERROR = 'error',
    SUCCESS = 'success'
}

export default new Vuex.Store({
    state: {
        appState: {
            isUpdateAvailable: false,
            isConnected: false,
            isLogin: false,
            snackbar: {
                isActive : false,
                text: '',
                timeout: 2000,
                type: 'info'
            }
        },
        applicationUser: new ApplicationUserBuilder().build(),
        ownProjects: new Array<Project>(),
        projects: new Array<Project>(),
        openOrderTypeForTable: new Array<string>()
    },
    getters: {
        projects: state => {
            return state.projects
        },
        openOrderTypeForTable: state => {
            return state.openOrderTypeForTable
        }
    },
    mutations: {
        connecting(state) {
            state.appState.isConnected = true
        },

        disconnecting(state, context) {
            state.appState.isConnected = false
            context.commit("removeLogin")
        },

        setApplicationUser(state, applicationUser) {
            state.applicationUser = applicationUser
        },

        setLogin(state){
            state.appState.isLogin = true
        },

        removeLogin(state){
            state.appState.isLogin = false
            state.applicationUser = new ApplicationUserBuilder().build()
        },

        activateSnackbar(state, {text, type}){
            state.appState.snackbar.isActive = true
            state.appState.snackbar.text = text
            state.appState.snackbar.type = type
        },
        addProjects(state, projectList){
            state.projects = projectList
        },
        openOrderTypeForTable(state, orderTypes: Array<string>){
            state.openOrderTypeForTable = orderTypes
        },
        removeOpenOrderTypeForTable(state, orderTypes: Array<string>){
            state.openOrderTypeForTable = new Array<string>()
        }
    },
    actions: {
        connect(context) {
            const user = "p.steinbrueck@neomit.de"
            const pw = "MM,9qdi*FhtPo?::DNKsx*.YF!PQvF37R&pNREb=qT4A+WXzsr_U.*q89Pyy-_z?"
            console.log('try to connecting')
            return new Promise<boolean>(resolve => {
                fireApp.auth().signInWithEmailAndPassword(user, pw)
                    .then((user) => {
                        context.commit('connecting')
                        //db.collection('costumer').get().then(el => console.log(el.docs.forEach(e => console.log(e.data()))))
                        resolve(true)
                    })
                    .catch(error => {
                        console.error(error)
                        console.error("connecting abort")
                        resolve(true)
                    })
            })

        },
        disconnect(context) {
            fireApp.auth().signOut()
                .then((user) => {
                    context.commit('disconnecting')
                })
                .catch(error => console.error(error))
        },

        login(context, {userName, password}) {
            console.log('login...')
            return new Promise<ApplicationUser>((resolve, reject) => {
                db.collection('user')
                    .where('username', '==', userName)
                    .where('password', '==', password)
                    .get()
                    .then(maybeUser => {
                        if (maybeUser != undefined && maybeUser.docs[0] != undefined) {
                            let data = maybeUser.docs[0].data()
                            delete data.password
                            let user = <ApplicationUser> data;
                            context.commit('setApplicationUser', user)
                            context.commit('setLogin')
                            resolve(user);
                        } else {
                            reject(new Error("not found"))
                        }
                    })
            })
        },
        logout(context) {
                context.commit('log')
        },

        createUser(context, user: ApplicationUser){
            console.log('createUser...')
            return new Promise<string>(((resolve, reject) => {
                DB_COLLECTION.USER
                    .where('username', '==', user.username)
                    .get()
                    .then(mayBeUser => {
                        if(!exists(mayBeUser)){
                            let crypPW =  SHA256(user.password).toString()
                            user.password = crypPW
                            DB_COLLECTION.USER.add(user).then(resp => {
                                context.commit('activateSnackbar', {
                                    text: i18n.t('success.200', {name: 'user'}),
                                    type: SnachbarType.INFO
                                })
                                resolve()
                            })
                        } else {
                            context.commit('activateSnackbar', {
                                text: i18n.t('error.409', {name: 'user'}),
                                type: SnachbarType.ERROR
                            })
                            reject()
                        }
                    })
            }))
        },
        updateUser(context, user: ApplicationUser){
            console.log('updateUser...')
            return new Promise<string>(((resolve, reject) => {
                DB_COLLECTION.USER
                    .where('username', '==', user.username)
                    .get()
                    .then(mayBeUser => {
                        if(exists(mayBeUser)){

                            mayBeUser.docs.forEach(doc => {
                                DB_COLLECTION.USER.doc(doc.id).update(user).then(resp => {
                                    context.commit('activateSnackbar', {
                                        text: i18n.t('success.200', {name: 'user'}),
                                        type: SnachbarType.INFO
                                    })
                                    resolve()
                                })
                            })


                        } else {
                            context.commit('activateSnackbar', {
                                text: i18n.t('error.404', {name: 'user'}),
                                type: SnachbarType.ERROR
                            })
                            reject()
                        }
                    })
            }))
        },
        deleteUser(context, user: ApplicationUser){
            return new Promise((resolve, reject) => {
                DB_COLLECTION.USER
                    .where('username', '==', user.username)
                    .get()
                    .then(mayBeUser => {
                        if (!mayBeUser.empty) {
                            mayBeUser.docs.forEach(doc => {
                                if (doc.exists) {
                                    console.log(doc.id, ' will be deleted')
                                    DB_COLLECTION.USER.doc(doc.id).delete()
                                        .then(() => resolve(true))
                                }
                            })
                        }
                    })
            })
        },
        resetUserPassword(context, user: ApplicationUser){
            return new Promise((resolve, reject) => {
                DB_COLLECTION.USER
                    .where('username', '==', user.username)
                    .get()
                    .then(mayBeUser => {
                        if (!mayBeUser.empty) {
                            mayBeUser.docs.forEach(doc => {
                                if (doc.exists) {
                                    console.log(doc.id, ' password will be updated')
                                    let crypPW =  SHA256(user.password).toString()
                                    user.password = crypPW
                                    DB_COLLECTION.USER.doc(doc.id).update(user)
                                        .then(() => resolve(true))
                                }
                            })
                        }
                    })
            })
        },
        getAllUsers(state){
            console.log('getAllUsers...')
            return new Promise<Array<ApplicationUser>>((resolve, reject) => {
                DB_COLLECTION.USER
                    .where('username', '!=', state.state.applicationUser.username)
                    .get()
                    .then(mayBeUsers => {
                        if (mayBeUsers != undefined && mayBeUsers.docs.length != 0) {
                            let users = mayBeUsers.docs.map(doc => {
                                let d =  doc.data()
                                //delete d.password
                               return (d as ApplicationUser)
                            } )
                            resolve(users);
                        } else {
                            reject(i18n.t('error.404'))
                        }
                    })
                    .catch(error => reject())
            })
        },

        createProject(context, project: Project){
            console.log('createProject...')
            return new Promise<string>((resolve, reject) => {
                DB_COLLECTION.PROJECT
                    .where('id', '==', project.id)
                    .get()
                    .then(mayBeProject => {
                        if(!exists(mayBeProject)){
                            DB_COLLECTION.PROJECT.doc(fomartID(project.id)).set(project).then(resp => {
                                context.commit('activateSnackbar', {
                                    text: i18n.t('success.200', {name: 'project'}),
                                    type: SnachbarType.INFO
                                })
                                resolve(fomartID(project.id))
                            })
                        }else {
                            context.commit('activateSnackbar', {
                                text: i18n.t('error.409', {name: 'project'}),
                                type: SnachbarType.ERROR
                            })
                            reject()
                        }
                    })
            })
        },
        updateProject(context, project: Project){
            console.log('updateProject...')
            return new Promise<string>((resolve, reject) => {
                DB_COLLECTION.PROJECT.doc(fomartID(project.id))
                    .update(project)
                    .then( () => {
                        context.commit('activateSnackbar', {
                            text: i18n.t('success.200', {name: 'project'}),
                            type: SnachbarType.INFO
                        })
                        resolve(fomartID(project.id))
                    }).catch( (error) => {
                    context.commit('activateSnackbar', {
                        text: i18n.t('error.409', {name: 'project'}),
                        type: SnachbarType.ERROR
                    })
                    console.log(error)
                    reject()
                })
            })
        },
        delteProject(context, project:Project){
            return new Promise<boolean>(resolve => {
                DB_COLLECTION.PROJECT.doc(fomartID(project.id)).delete()
                DB_COLLECTION.USER.get().then(mayBeUsers => {
                    if (mayBeUsers != undefined && mayBeUsers.docs.length != 0) {
                        let users = mayBeUsers.docs.map(doc => {
                            let d =  doc.data()
                            //delete d.password
                            return (d as ApplicationUser)
                        } )
                        users.forEach(u => {
                            u.customer!.projectsNr = u.customer!.projectsNr.filter(i => i !== fomartID(project.id))
                            context.dispatch('updateUser', u).then(()=> resolve(true))
                        })

                    }
                })
            })


        },
        getAllProjects(context){
            console.log('getAllProjects...')
            return new Promise<Array<Project>>( (resolve, reject) => {
                DB_COLLECTION.PROJECT
                    .get()
                    .then(mayBeProject => {
                        if (mayBeProject != undefined && mayBeProject.docs.length != 0) {
                            let projects = mayBeProject.docs.map(doc => doc.data() as Project )
                            context.commit('addProjects', projects)
                            resolve(projects);
                        } else {
                            reject(i18n.t('error.404'))
                        }
                    })
                    .catch(error => reject())
            } )
        },
        getAllUnfinishedProjects(context){
            return new Promise<Array<Project>>( (resolve, reject) => {
                DB_COLLECTION.PROJECT
                    .where('status', '!=', 'COMPLETED')
                    .get()
                    .then(mayBeProject => {
                        if (mayBeProject != undefined && mayBeProject.docs.length != 0) {
                            let projects = mayBeProject.docs.map(doc => doc.data() as Project )
                            context.commit('addProjects', projects)
                            resolve(projects);
                        } else {
                            reject(i18n.t('error.404'))
                        }
                    })
                    .catch(error => {
                        reject()
                        console.error(error)
                    })
            } )
        },

        getOwnProjects(context, projectNumbers:[string]){
            if(projectNumbers === undefined || projectNumbers.length <= 0) {
                return  i18n.t('error.404')
            }
            return new Promise<Array<Project>>( (resolve, reject) =>  {
                DB_COLLECTION.PROJECT
                    .where(fstore.FieldPath.documentId(), 'in', projectNumbers.map(id => fomartID(id)))
                    //.doc('/' + projectNumbers[0])
                    .get()
                    .then( listOfMaybeProjects => {
                        if (!listOfMaybeProjects.empty){
                            resolve(listOfMaybeProjects.docs.map( doc => doc.data() as Project))
                        }
                    })
            })
        },
        FETCH_OWN_PROJECTS: ({state}, projectNumbers:[string]) => {
            if(projectNumbers === undefined || projectNumbers.length <= 0) {
                return  i18n.t('error.404')
            }
            return new Promise<Array<Project>>(res => {
                let batches = []
                while (projectNumbers.length){
                    const batch = projectNumbers.splice(0,10)
                    batches.push(
                        new Promise<Array<Project>>(resolve => {
                            DB_COLLECTION.PROJECT
                                .where(fstore.FieldPath.documentId(), 'in', batch.map(id => fomartID(id)))
                                .get()
                                .then(listOfMaybeProjects => {
                                    if (!listOfMaybeProjects.empty){
                                        resolve(listOfMaybeProjects.docs.map( doc => doc.data() as Project))
                                    }
                                })
                        })
                    )
                }
                Promise.all(batches).then(content => {
                    res(content.flat());
                })
            })
        }
    },
})

function fomartID(id:string) {
    return id
        .toLowerCase()
        .replaceAll('-', '')
        .replaceAll('_', '')
        .replaceAll(' ', '')
        .replaceAll(',', '')
        .replaceAll('.', '')
        .replaceAll('/', '')
        .replaceAll('#', '')
}
