import {Costumer} from "@/data/model/Costumer";

export interface ApplicationUser {
    name?: string
    username:string
    password:string
    role: Role
    customer?: Costumer
}

export enum Role {
    USER = 'USER',
    ADMIN = 'ADMIN',
}




