import {ApplicationUser, Role} from "@/data/model/ApplicationUser";
import {CustomerBuilder} from "@/data/builder/CustomerBuilder";
import {Costumer} from "@/data/model/Costumer";

export class ApplicationUserBuilder {
    private readonly applicationUser: ApplicationUser

    constructor() {
        this.applicationUser = {
            name: '',
            username: '',
            role: Role.USER,
            customer: new CustomerBuilder().build(),
            password: this.makePW(8)
        }
    }

    name(name: string): ApplicationUserBuilder{
        this.applicationUser.name = name
        return this
    }

    username(username:string): ApplicationUserBuilder{
        this.applicationUser.username = username
        return this
    }

    role(role:Role): ApplicationUserBuilder{
        this.applicationUser.role = role
        return this
    }

    customer(customer:Costumer): ApplicationUserBuilder{
        this.applicationUser.customer = customer
        return this
    }

    build(){
        return this.applicationUser
    }

    public makePW(length: number):string {
        let result           = '';
        let characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        let charactersLength = characters.length;
        for ( let i = 0; i < length; i++ ) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
    }
}
