import Vue from 'vue'
import VueRouter, {RouteConfig} from 'vue-router'
import Login from '../views/Login.vue'
import store from '../store'
import {Role} from "@/data/model/ApplicationUser";

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
    //Basic routing
  { path: '/', name: 'Login', component: Login },

  {path: '/admin/managedashboard', name: 'Dashboard', component: () => import('../views/ManageDashboard.vue')},
  {path: '/admin/manageprojects', name: 'manageprojects', component: () => import('../views/ManageProjects.vue')},
  {path: '/admin/manageuser', name: 'manageuser', component: () => import('../views/ManageUser.vue')},

  {path: '/variantone', name: 'variantone', component: () => import('../components/CostumerOwerview1.vue')},
  {path: '/variantTwo', name: 'variantTwo', component: () => import('../components/CostumerOwerview2.vue')},
  {path: '/variantthree', name: 'variantthree', component: () => import('../components/ProjectOverview.vue')},

  {path: '/overview', name: 'overview', component: () => import('../views/ProjectOverview.vue')}
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if(to.name != 'Login'){
    if(!store.state.appState.isLogin){
      next('/')
    }
  }

  if(to.path.includes('admin')){
    if(store.state.applicationUser.role.toString() === 'USER'){
      router.push('/')
    }
  }
  next();

})

export default router
