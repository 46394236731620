import { Vue } from 'vue-property-decorator'

export default class Base extends Vue {
    private translationPrefix: string

    constructor (translationPrefix: string) {
        super()
        this.translationPrefix = translationPrefix
    }

    protected getLangText (path: string, data = ''): string {
        return this.getLangTextWithoutPrefix(this.translationPrefix + '.' + path, data)
    }

    protected getLangTextWithoutPrefix (path: string, data = ''): string {
        return this.$t(path, data).toString()
    }

    protected getLangTextWithoutPrefixAndWithoutHTML  (path: string, data = ''): string {
        return this.$t(path, data).toString().replace( /(<([^>]+)>)/ig, ' ')
    }

    protected getLangTextWithoutHTML (path: string, data = ''): string {
        return this.getLangText(path, data).toString().replace( /(<([^>]+)>)/ig, '')
    }

    protected prettyDate (rawDate: any) {
        if (!rawDate) return ''
        const date = new Date(rawDate)
        return `${date.toLocaleDateString()}`
    }

    protected getWeekNumber(rawDate: any){
        if (!rawDate) return ''
        const date = new Date(rawDate)

        const d = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
        const dayNum = d.getUTCDay() || 7;
        d.setUTCDate(d.getUTCDate() + 4 - dayNum);
        const yearStart = new Date(Date.UTC(d.getUTCFullYear(),0,1));

        const diff = date.getDate() - date.getDay() + (date.getDay() == 0 ? -6 : 1); // adjust when day is sunday
        let monday = new Date(date.setDate(diff));

        const diff2 = date.getDate() - date.getDay() + 7; // adjust when day is sunday
        let friday = new Date(date.setDate(diff2));

        const calenderWeek = Math.ceil((((d.getTime() - yearStart.getTime()) / 86400000) + 1)/7);
        return `${calenderWeek}. KW (${this.prettyDate(monday)} - ${this.prettyDate(friday)})`
    }

    get isTestSystem(){
        return process.env.NODE_ENV === 'development'
    }

    protected get generalRules () {
        return {
            notEmpty: [
                (input: string) => !!input || this.getLangTextWithoutPrefix('app.form.general.notEmpty')
            ]
        }
    }

    protected fomartID(id:string) {
        return id
            .toLowerCase()
            .replaceAll('-', '')
            .replaceAll('_', '')
            .replaceAll(' ', '')
            .replaceAll(',', '')
            .replaceAll('.', '')
            .replaceAll('/', '')
            .replaceAll('#', '')
    }
}

export interface Form {
    validate(): boolean;
    reset(): void;
}
