import Vue from 'vue'
import { firestorePlugin } from 'vuefire'
import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/auth'

let isStaging = process.env.NODE_ENV === 'development'

let firebaseConfig_staging= {
    apiKey: "AIzaSyCbV26SrxPIWvsN_iZdabh_X2mXHimJbbA",
    authDomain: "neubauer-f2e14.firebaseapp.com",
    databaseURL: "https://neubauer-f2e14.firebaseio.com",
    projectId: "neubauer-f2e14",
    storageBucket: "neubauer-f2e14.appspot.com",
    messagingSenderId: "23753357756",
    appId: "1:23753357756:web:8944c4a864987b17fed3b9"
};

let firebaseConfig_live = {
    apiKey: "AIzaSyCyaSKamkN5zF1VEtBa9cPjbvJsL5N8OR4",
    authDomain: "neubauer-live.firebaseapp.com",
    projectId: "neubauer-live",
    storageBucket: "neubauer-live.appspot.com",
    messagingSenderId: "489429180621",
    appId: "1:489429180621:web:69a65a8bfc2ce24c060984"
};

Vue.use(firestorePlugin)

export const fireApp: firebase.app.App = firebase.initializeApp((isStaging) ? firebaseConfig_staging : firebaseConfig_live);

export const db: firebase.firestore.Firestore = fireApp.firestore()

export const fstore = firebase.firestore

export const DB_COLLECTION = {
    USER:  db.collection('user'),
    PROJECT: db.collection('project')
}





