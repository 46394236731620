import {Address} from "@/data/model/Address";

export class AddressBuilder {
    private readonly address: Address

    constructor() {
        this.address = {
            addressline: [],
            city: '',
            country: '',
            fullname: '',
            postcode: '',
            state: '',
        }
    }

    addressline(addressline: Array<string>): AddressBuilder{
        this.address.addressline = addressline
        return this
    }

    country(country: string): AddressBuilder{
        this.address.country = country
        return this
    }

    fullname(fullname: string): AddressBuilder{
        this.address.fullname = fullname
        return this
    }

    postcode(postcode: string): AddressBuilder{
        this.address.postcode = postcode
        return this
    }

    state(state: string): AddressBuilder{
        this.address.state = state
        return this
    }

    build(): Address{
        return this.address
    }
}
