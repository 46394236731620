







import {Component, Vue} from "vue-property-decorator";

@Component({})

export default class extends Vue {


  get iconPath(): any{
      if(!this.$slots.default) {
        return 'mdi-alert-circle-outline'
      }
      return 'mdi-' + this.$slots.default[0].text
    }
}


