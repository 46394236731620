


























































import Icon from "@/components/Icon.vue";
import {Component, Ref} from "vue-property-decorator";
import Base, {Form} from "@/data/model/Base";
import {ApplicationUser, Role} from "@/data/model/ApplicationUser";

var SHA256 = require("crypto-js/sha256");

@Component({
  components: {Icon},
})

export default class Login extends Base {
  private isValid = false
  private loadingScreen = false
  private wrongUserOrPw = false
  private showPw = false

  private userName = ''
  private userPassword = ''
  // 5e884898da28047151d0e56f8dc6292773603d0d6aabbdd62a11ef721d1542d8
  // MasterPW

  @Ref('form')
  form!: Form


  constructor() {
    super('login')
  }

  get userNameRules() {
    return [
      (v: string) => !!v || this.getLangText('notEmptyEmail'),
      (v: string) => /.+@.+\..+/.test(v) || this.getLangText('wrongFormatEmail'),
    ]
  }

  get userPasswordRules() {
    return [
      (v: string) => !!v || this.getLangText('notEmptyPassword'),
    ]
  }

  private validateAndDoLogin() {
    if (this.form.validate()) {
      this.doLogin()
    }
  }

  private doLogin() {
    this.loadingScreen = true
    this.$store.dispatch('connect').then(() => {
      this.$store.dispatch('login', {
        userName: this.userName,
        password: this.$options.filters!.encrypt(this.userPassword)
      })
          .then((user: ApplicationUser) => {
            console.log(user)
            this.$store.state.appState.isLogin = true
            this.loadingScreen = false
            this.userPassword = ''
            this.userName = ''
            console.log(Role.ADMIN.toString())
            if (Role.ADMIN.toString() === user.role.toString()) {
              this.$router.push("/admin/managedashboard")
            } else {
              this.$router.push("/overview")
            }
            //
          }).catch(() => {
        this.loadingScreen = false
        this.wrongUserOrPw = true
        setTimeout(() => {
          this.wrongUserOrPw = false
        }, 2000)
      })
    })
  }

}
