import {Costumer} from "@/data/model/Costumer";
import {Address} from "@/data/model/Address";
import {AddressBuilder} from "@/data/builder/AddressBuilder";

export class CustomerBuilder {
    private readonly customer: Costumer

    constructor() {
        this.customer = {
            name: '',
            address: [new AddressBuilder().build()],
            projectsNr: [],
        }
    }

    address(address: Array<Address>): CustomerBuilder{
        this.customer.address = address
        return this
    }

    projectsNr(projectsNr: Array<string>): CustomerBuilder{
        this.customer.projectsNr = projectsNr
        return this
    }

    build(){
        return this.customer
    }
}
