





























































































































import Base from "@/data/model/Base";
import {Component} from "vue-property-decorator";

@Component({})
export default class App extends Base {
  constructor() {
    super('app')
  }
  get buttonbarlinks() {
    return [
      {icon: 'mdi-account-multiple', label: this.getLangText('link_label_user'), link: '/admin/manageuser'},
      {icon: 'mdi-home', label: this.getLangText('link_label_dashboard'), link: '/admin/managedashboard'},
      {icon: 'mdi-home-city', label: this.getLangText('link_label_projects'), link: '/admin/manageprojects'},
    ]
  }


  get links() {
    return [
      {label: this.getLangTextWithoutPrefix('footer.home'), link: 'https://ibneubauer.com/'},
      {label: this.getLangTextWithoutPrefix('footer.services'), link: 'https://ibneubauer.com/leistungen/'},
      {label: this.getLangTextWithoutPrefix('footer.tc-partner'), link: 'https://ibneubauer.com/tc-partner/'},
      {label: this.getLangTextWithoutPrefix('footer.About-us'), link: 'https://ibneubauer.com/ueber-uns/'},
      {
        label: this.getLangTextWithoutPrefix('footer.jobs-and-training'),
        link: 'https://ibneubauer.com/jobs-und-ausbildung/'
      },
      {label: this.getLangTextWithoutPrefix('footer.news'), link: 'https://ibneubauer.com/aktuelles/'},
      {
        label: this.getLangTextWithoutPrefix('footer.contact-imprint'),
        link: 'https://ibneubauer.com/kontaktimpressum/'
      },
    ]
  }

  logout() {
    this.$store.dispatch('logout')
    this.$router.push('/')
    this.$store.state.appState.isLogin = false
  }

  updateApp(){
    window.location.reload()
  }
};
